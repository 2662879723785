import decode from 'jwt-decode';

const token_item = 'hub_token';
const user_item = 'hub_user';

export function login(token, user) {
    localStorage.setItem(token_item, token)
    localStorage.setItem(user_item, JSON.stringify(user))
}

export function logout() {
    localStorage.removeItem(token_item);
    localStorage.removeItem(user_item);
}

export function getToken() {
    return localStorage.getItem(token_item)
}

export function getUser() {
    return JSON.parse(localStorage.getItem(user_item))
}

export function createConfig() {
    const token = getToken();
    return token
        ? { headers: {'Authorization': 'Bearer ' + getToken()} }
        : {}
}

export function loggedIn() {
    const token = getToken()
    return !!token && !isTokenExpired(token)
}

export function isTokenExpired(token) {
    try {
        const decoded = decode(token);
        if (decoded.exp < Date.now() / 1000) {
            return true;
        }
        else
            return false;
    }
    catch (err) {
        return false;
    }
}

export function extractRedirect() {
    let p = window.location.search;
    p = p.replace('&', '%')
    const params = new URLSearchParams(p);
    return params.has('redirectTo') ? params.get('redirectTo').replace('%', '&') : '/';
}


export function redirectToLogin() {
    return `/login?redirectTo=${window.location.pathname + window.location.search}`;
}
