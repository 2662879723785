import React, { Component } from 'react';
import * as Auth from './AuthService';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import {permissionMapper} from "./UserManagement";
import Menu from "./Menu";
import './Account.css';import Button from 'react-bootstrap-button-loader';
import axios from "axios";
import {properties} from "./properties";


class Account extends Component {

    request(){
        axios.get(`${properties.apiUrl}/api/password-change-request?email=${Auth.getUser().email}&type=short`, Auth.createConfig())
            .then(json => alert('Email with instructions was sent to ' + Auth.getUser().email))
            .catch(error => {
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error.response.data)
                }
            });
    }

    render() {
        let info = [];
        for (let key in Auth.getUser()){
            if (key !== 'can_open') {
                if(key === 'permissions'){
                    info.push(<li className='info-row'><b>{properties[key]}:</b> {permissionMapper(Auth.getUser()[key])+"  "}</li>)
                }else {
                    info.push(<li className='info-row'><b>{properties[key]}:</b> {Auth.getUser()[key]+"  "}</li>)
                }
            }
        }

        return (
            <div className="Account-root center-block">
                <div className="App-query center-block row">
                    <a href='/'><span className="col-md-3 App-logo"/></a>
                </div>
                <div className='info-row  center-block'>Your profile information:
                    <ul>
                        {info}
                    </ul>
                    <Button type="submit" bsStyle="primary" style={{float: 'right'}} onClick={() => this.request()}>Request password change</Button>
                </div>
                <Menu history={this.props.history}/>
            </div>
        );
    }

}

export default Account
