import './Users.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import './index.css';

import React, { Component } from 'react';
import {Form, FormGroup, FormControl, ControlLabel, Checkbox, Modal} from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import axios from 'axios';

import { properties } from './properties.js';
import * as Auth from './AuthService';

require('promise.prototype.finally').shim();

const DatePicker = require("react-16-bootstrap-date-picker");
const FileDownload = require('js-file-download');

export class Report extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dateFrom: null,
            dateFromStr: null,
            dateTo: null,
            dateToStr: null,
            triedToSubmit: false,
            submitInProgress: false
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        if (this.state.dateFrom != null) {
            this.setState({submitInProgress: true});

            let url = `${properties.apiUrl}/api/user/${this.props.endpoint}?from=${this.state.dateFromStr}`;
            if (this.state.dateTo != null) {
                url += `&to=${this.state.dateToStr}`
            }

            const config = Auth.createConfig();
            config['responseType'] = 'blob';

            axios.get(url, config)
                .then(response => {
                    const header = response.headers['content-disposition'];
                    const filename = /filename=(.*)/.exec(header)[1];
                    FileDownload(response.data, filename, filename);
                })
                .catch(error => alert(error))
                .finally(() => this.setState({
                    triedToSubmit: false,
                    submitInProgress: false
                }));
        }
    }

    checkRequired(value) {
        return (value == null && this.state.triedToSubmit) ? 'error' : null;
    }

    render() {
        return (
            <Modal show onHide={this.props.onClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={e => this.handleSubmit(e)}>
                        <FormGroup controlId="dateFrom" className="required col-md-6 Report-date-from"
                                   validationState={this.checkRequired(this.state.dateFrom)}>
                            <ControlLabel>Date From</ControlLabel>
                            <DatePicker value={this.state.dateFrom} dateFormat="YYYY-MM-DD"
                                        onChange={(value, formattedValue) => this.setState({dateFrom: value, dateFromStr: formattedValue})}
                            />
                        </FormGroup>
                        <FormGroup controlId="dateTo" className="col-md-6 Report-date-to">
                            <ControlLabel>Date To (inclusive)</ControlLabel>
                            <DatePicker value={this.state.dateTo} dateFormat="YYYY-MM-DD"
                                        onChange={(value, formattedValue) => this.setState({dateTo: value, dateToStr: formattedValue})}
                                        onInvalid={e => this.onInvalid(e)}
                            />
                        </FormGroup>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" onClick={e => this.handleSubmit(e)} bsStyle="primary"
                            loading={this.state.submitInProgress}>Download</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

class Users extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            company: '',
            position: '',
            location:'',
            isOperator: false,
            isAdmin: false,
            isInternal: false,
            isSuperAdmin: false,
            triedToSubmit: false,
            submitInProgress: false,
            isUpdate:false,
            sendEmail: false,
            showEdit: false,
            url: `${properties.apiUrl}/api/user`
        };
    }

    componentDidMount() {
        if (this.props.user) {
            let user = this.props.user;
            this.setState({
                isUpdate: true,
                email: user.email,
                firstName: user.firstName ? user.firstName : '',
                lastName: user.lastName ? user.lastName : '',
                phone: user.phone ? user.phone : '',
                company: user.company ? user.company : '',
                position: user.position ? user.position : '',
                location: user.location ? user.location : '',
                isOperator: user.permissions.indexOf(properties.permission_operator) > -1,
                isAdmin: user.permissions.indexOf(properties.permission_admin) > -1,
                isInternal:  user.permissions.indexOf(properties.permission_internal) > -1,
                isSuperAdmin: user.permissions.indexOf(properties.permission_super) > -1,
                viewOnly:user.permissions.indexOf(properties.permission_read) > -1,
                url: `${properties.apiUrl}/api/user/update`
            })
        }
        if (this.props.isSuperAdmin) {
            axios.get(`${properties.apiUrl}/api/operators?show_all=true`, Auth.createConfig())
                .then(json => this.setState({operators: json.data}))
                .catch(error => console.log(error));
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.props !== nextProps || this.state !== nextState
    }

    validateField (field){
        return field.length !== 0;
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        if (this.state.email.length > 0) {
            this.setState({submitInProgress: true});

            let permissions = [];
            if (this.state.isOperator) {
                permissions.push('operator');
            }
            if (this.state.isAdmin) {
                permissions.push('admin');
            }
            if (this.state.isInternal) {
                permissions.push('internal');
            }
            if(this.state.viewOnly){
                permissions.push('view');
            }
            if (this.state.isSuperAdmin) {
                permissions.push('superadmin');
            }
            let company = !this.props.isSuperAdmin ? Auth.getUser().company : this.state.company;
            let user = {
                'email': this.state.email,
                'firstName': this.state.firstName,
                'lastName': this.state.lastName,
                'company':company
            };
            let isValid = true;
            for(let key in user){
                isValid = isValid && this.validateField(user[key]);
            }
            if (isValid) {
                user.phone = this.state.phone;
                user.permissions = permissions;
                user.location = this.state.location;
                user.position = this.state.position;
                let url = this.state.url + `?send_email=${this.state.sendEmail}`;
                axios.post(url, user, Auth.createConfig())
                    .then((json) => {
                        this.props.handleUpdate(json.data);
                        alert(`User ${this.state.email} was successfully ` + (this.state.isUpdate ? 'updated' : `created`));
                        this.props.onClose()
                    })
                    .catch(error => alert(error.response.data))
                    .finally(() => this.setState({
                        triedToSubmit: false,
                        submitInProgress: false
                    }));
            } else {
                alert('Some fields are empty!');
                this.setState({
                    submitInProgress: false
                })
            }
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    resetPassword(){
        axios.get(`${properties.apiUrl}/api/password-change-request?email=${this.state.email}&type=long`, Auth.createConfig())
            .then((json) => {
                // this.props.handleUpdate(json.data);
                alert(`Password for user ${this.state.email} was successfully reseted`);
            })
            .catch(error => alert(error))
            .finally(() => this.setState({
                triedToSubmit: false,
                resetInProgress: false
            }));
    }

    createSelectItems() {
        let items = [];
        if (this.props.isSuperAdmin && this.state.operators) {
            items.push(<option selected key={0}/>);
            for (let i = 0; i < this.state.operators.length; i++) {
                items.push( <option key={i+1} value={this.state.operators[i]}>{this.state.operators[i]}</option>);
            }
        } else{
            let company = Auth.getUser().company;
            items.push(<option key={1} value={company} selected>{company}</option>);
        }
        return items;
    }

    render() {
        let title = this.state.isUpdate ? 'Update user' : `Create user`;

        return (
            <div className="Users-root center-block">
                <Modal show>
                    <Modal.Header closeButton onHide={this.props.onClose}>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="User-form">
                            <FormGroup controlId="email" className="required"
                                       validationState={this.checkRequired(this.state.email)}>
                                <ControlLabel>Email</ControlLabel>
                                <FormControl type="email" value={this.state.email}
                                             onChange={e => this.setState({email: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="firstName" className="required"
                                       validationState={this.checkRequired(this.state.firstName)}>
                                <ControlLabel>First Name</ControlLabel>
                                <FormControl type="text" value={this.state.firstName}
                                             onChange={e => this.setState({firstName: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="lastName" className="required"
                                       validationState={this.checkRequired(this.state.lastName)}>
                                <ControlLabel>Last Name</ControlLabel>
                                <FormControl type="text" value={this.state.lastName}
                                             onChange={e => this.setState({lastName: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="phone">
                                <ControlLabel>Phone Number</ControlLabel>
                                <FormControl type="text" value={this.state.phone}
                                             onChange={e => this.setState({phone: e.target.value})}/>
                            </FormGroup>
                            {!this.state.showEdit && <FormGroup controlId="company" className="required">
                                <ControlLabel>Company</ControlLabel>
                                <FormControl componentClass="select" value={this.state.company}
                                             onChange={e => this.setState({company: e.target.value})}>
                                    {this.createSelectItems()}
                                </FormControl>
                                {( this.props.isSuperAdmin) && <a  onClick={() => this.setState({showEdit: true})}>Your company is not at the list?</a>}
                            </FormGroup>}
                            {this.state.showEdit && <FormGroup controlId="company" className="required" validationState={this.checkRequired(this.state.company)}>
                                <ControlLabel>Company</ControlLabel>
                                <FormControl type="text" value={this.state.company}
                                             required
                                             onChange={e => this.setState({company: e.target.value})}/>
                                <a style={{float:'right'}} onClick={() => this.setState({showEdit: false, company:''})}>Back</a> </FormGroup>}
                            <FormGroup controlId="position">
                                <ControlLabel>Position</ControlLabel>
                                <FormControl type="text" value={this.state.position}
                                             onChange={e => this.setState({position: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="location">
                                <ControlLabel>Location</ControlLabel>
                                <FormControl type="text" value={this.state.location}
                                             onChange={e => this.setState({location: e.target.value})}/>
                            </FormGroup>
                            <FormGroup controlId="permissions">
                                <ControlLabel>Permissions</ControlLabel>
                                <div>
                                    <Checkbox checked={this.state.viewOnly}
                                              onChange={e => this.setState({viewOnly: !this.state.viewOnly})}>
                                        {properties.permission_read}
                                    </Checkbox>
                                    <Checkbox checked={this.state.isInternal}
                                              onChange={e => this.setState({isInternal: !this.state.isInternal})}>
                                        {properties.permission_internal}
                                    </Checkbox>
                                    <Checkbox checked={this.state.isOperator}
                                              onChange={e => this.setState({isOperator: !this.state.isOperator})}>
                                        {properties.permission_operator}
                                    </Checkbox>
                                    <Checkbox checked={this.state.isAdmin}
                                              onChange={e => this.setState({isAdmin: !this.state.isAdmin})}>
                                        {properties.permission_admin}
                                    </Checkbox>
                                    {this.props.isSuperAdmin &&
                                        <Checkbox checked={this.state.isSuperAdmin}
                                                  onChange={e => this.setState({isSuperAdmin: !this.state.isSuperAdmin})}>
                                            {properties.permission_super}
                                        </Checkbox>
                                    }
                                </div>
                            </FormGroup>
                            {this.state.isUpdate && <FormGroup controlId="sendEmail">
                                <ControlLabel>Settings</ControlLabel>
                                <Checkbox checked={this.state.sendEmail}
                                      onChange={e => this.setState({sendEmail: !this.state.sendEmail})}>
                                    Notify {this.state.email} about information changes
                                </Checkbox>
                            </FormGroup>}
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.isUpdate &&
                        <Button type="default" onClick={e => this.resetPassword(e)} style={{float:'left'}} bsStyle="primary"
                                loading={this.state.resetInProgress}>Reset password</Button>
                        }
                        <Button type="submit" onClick={e => this.handleSubmit(e)} bsStyle="primary"
                                loading={this.state.submitInProgress}>Submit</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default Users;
