import './Menu.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import './index.css';

import { HomeOutlined } from '@ant-design/icons';
import React, { Component } from 'react';
import { Modal, FormGroup, FormControl, ControlLabel, Image } from 'react-bootstrap';
import Button from 'react-bootstrap-button-loader';

import axios from 'axios';

import { properties } from './properties.js';
import * as Auth from './AuthService';

var Recaptcha = require('react-recaptcha');
const FileDownload = require('js-file-download');

class ContactUsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            phone: '',
            message: '',
            recaptchaResponse: null,
            triedToSubmit: false,
            submitInProgress: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show &&  Auth.loggedIn()) {
            const user = Auth.getUser();
            this.setState({
                name: `${user.firstName} ${user.lastName}`,
                email: `${user.email}`,
                phone: `${user.phone ? user.phone : ''}`
            })
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        if (!this.state.recaptchaResponse) {
            document.getElementById('g-recaptcha').classList.add('empty');
        } else if (this.state.name.length > 0 && this.state.email.length > 0 && this.state.message.length > 0) {
            this.setState({submitInProgress: true});
            axios.post(`${properties.apiUrl}/api/email/contact`, {
                'name': this.state.name,
                'email': this.state.email,
                'phone': this.state.phone,
                'message': this.state.message,
                'recaptchaResponse': this.state.recaptchaResponse
            }, Auth.createConfig())
                .then(json => {
                    this.setState({submitInProgress: false});
                    this.close();
                })
                .catch(error => {
                    if(error.response.status === 401){
                        alert('Your session expired! Please login again!');
                        Auth.logout();
                        this.props.history.replace(Auth.redirectToLogin());
                    } else {
                        alert(error.response.data)
                    }
                });
        }
    }

    checkRequired(value) {
        return (value.length === 0 && this.state.triedToSubmit) ? 'error' : null;
    }

    verifyRecaptcha(response) {
        if (response) {
            this.setState({recaptchaResponse: response});
            document.getElementById('g-recaptcha').classList.remove('empty');
        }
    }

    close() {
        this.setState({
            name: '',
            email: '',
            phone: '',
            message: '',
            recaptchaResponse: null,
            triedToSubmit: false,
            submitInProgress: false
        });
        this.props.onClose();
    }

    render() {
        return (
            <Modal show={this.props.show} onHide={() => this.close()}>
                <form className="Contact-form" onSubmit={e => this.handleSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Contact Us</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup controlId="name" className="required"
                                   validationState={this.checkRequired(this.state.name)}>
                            <ControlLabel>Name</ControlLabel>
                            <FormControl type="text" value={this.state.name}
                                         onChange={e => this.setState({name: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="email" className="required"
                                   validationState={this.checkRequired(this.state.email)}>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl type="email" value={this.state.email}
                                         onChange={e => this.setState({email: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="phone">
                            <ControlLabel>Phone Number</ControlLabel>
                            <FormControl type="text" value={this.state.phone}
                                         onChange={e => this.setState({phone: e.target.value})}/>
                        </FormGroup>
                        <FormGroup controlId="message" className="required"
                                   validationState={this.checkRequired(this.state.message)}>
                            <ControlLabel>Message</ControlLabel>
                            <FormControl componentClass="textarea" value={this.state.message}
                                         onChange={e => this.setState({message: e.target.value})}/>
                        </FormGroup>
                        <div className="text-center">
                            <Recaptcha
                                sitekey="6LfAEpwUAAAAAPvayyQjTN3-QzUdY1WXDQPRfgVD"
                                render="explicit"
                                verifyCallback={response => this.verifyRecaptcha(response)}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button type="submit" bsStyle="primary" loading={this.state.submitInProgress}>Submit</Button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }
}

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContactForm: false,
            triedToSubmit: false,
            submitInProgress: false
        }
    }

    handleDownload(e) {
        e.preventDefault();
        this.setState({triedToSubmit: true});
        let guide = (window.location.href.includes('admin') || window.location.href.includes('users')) ? 'admin' : 'user';
        let url = `${properties.apiUrl}/api/download/qrg?type=${guide}`;

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        axios.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1].replace(/"/g,'');
                FileDownload(response.data, filename, filename);
            })
            .catch(error => {
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    alert(error)
                }
            })
            .finally(() => this.setState({
                triedToSubmit: false,
                submitInProgress: false
            }));
    }

    logout() {
        Auth.logout();
        this.props.history.replace(Auth.redirectToLogin());
    }

    render() {
        let title =  (window.location.href.includes('admin') || window.location.href.includes('users')) ? 'Download quick reference guide for administrators' : 'Download quick reference guide';
        return (
            <div>
                <ContactUsModal show={this.state.showContactForm} onClose={() => this.setState({showContactForm: false})}/>
                {this.props.logout !== false &&
                <div id="search">
                    {Auth.getUser() &&
                    <div className="menu-direction">
                        <a href='/'>
                            <HomeOutlined id="home-button"  className='icon-home'/>
                        </a>
                        <div style={{display: "inline", paddingTop: -5}}>

                        {Auth.getUser().can_open &&  <a href='/users'><Image src="/images/account-multiple.png"/></a>}
                        {Auth.getUser().can_open &&  <a href='/admin'><Image src="/images/icon.png"/></a>}
                        <a href='/search'><Image src="/images/magnify.png"/></a>
                        <a href='/user'><Image src="/images/account-circle-outline.png"/></a>
                        </div>
                    </div>}

                </div>}
                <div id="menu">
                    <div className="menu-direction">
                    {this.props.logout !== false &&
                    <Button id="downloadqrg" bsStyle="link" onClick={event => this.handleDownload(event)} title={title}>
                        <Image src="/images/qrg.png"/>
                    </Button>
                    }
                    {this.props.contactUs !== false &&
                        <Button id="contactUsLink" bsStyle="link" onClick={() => this.setState({showContactForm: true})} title="Contact Us">
                            <Image src="/images/contact-us.png"/>
                        </Button>}
                    {this.props.logout !== false &&
                        <Button id="logout" bsStyle="link" onClick={() => this.logout()} title="Log Out">
                            <Image src="/images/logout.png"/>
                        </Button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default Menu;
