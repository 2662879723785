import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import { Table, Image} from 'react-bootstrap';
import './UserManagement.css';
import * as Auth from './AuthService';
import Users, {Report} from './Users'
import Menu from "./Menu";
import {notify} from "react-notify-toast";
import * as _ from 'lodash';
import axios from "axios";
import {properties} from "./properties";
import {ListItemText, Divider, Checkbox, Select, MenuItem, InputLabel} from "@material-ui/core";
import Redirect from "react-router-dom/Redirect";

const FileDownload = require('js-file-download');

export function permissionMapper(permissions) {
    let perms = [];
    for (let i = 0; i < permissions.length; i++) {
        if (permissions[i] === 'without') {
            perms.push(properties.permission_regular)
        } else if (permissions[i] === 'view') {
            perms.push(properties.permission_read)
        } else if (permissions[i] === 'internal') {
            perms.push(properties.permission_internal)
        } else if (permissions[i] === 'operator') {
            perms.push(properties.permission_operator)
        } else if (permissions[i] === 'admin') {
            perms.push(properties.permission_admin)
        } else if (permissions[i] === 'superadmin') {
            perms.push(properties.permission_super)
        }
    }
    return perms;
}

class Search extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            string: '',
            permission: permissionMapper(['without', 'view','internal', 'operator', 'admin', 'superadmin'], true),
            permissions: permissionMapper(['without', 'view', 'internal','operator', 'admin', 'superadmin'], true)
        }
    }

    componentDidMount() {
        axios.get(`${properties.apiUrl}/api/operators?show_all=true`, Auth.createConfig())
            .then(json => this.setState({operators: json.data, companies: json.data}))
            .catch(error => console.log(error.response.data));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.users && this.props.users !== prevProps.users){
            axios.get(`${properties.apiUrl}/api/operators?show_all=true`, Auth.createConfig())
                .then(json => this.setState({operators: json.data, companies: json.data}))
                .catch(error => {
                    console.log(error.response.data);
                    if(error.response.status === 401){
                        alert('Your session expired! Please login again!');
                        Auth.logout();
                        this.props.history.replace(Auth.redirectToLogin());
                    }
                });
        }
    }

    handleSearch = (event) => {
        event.preventDefault();
        this.search.focus();
        if (!this.props.searchInProgress) {
            this.props.onSearch(this.state.string, this.state.companies, this.state.permission);
        } else {
            notify.show("Previous query is still executing. Please wait till the results show up", "error", 5000);
        }
    };

    handleInput(event) {
        this.setState({string: event.target.value});
    }

    clearSelection(event) {
        event.preventDefault();
        this.search.focus();
        this.setState({
            companies: this.state.operators,
            permission: this.state.permissions
        });
        if (!this.props.searchInProgress) {
            this.props.onSearch('', 'all', 'all');
        } else {
            notify.show("Previous query is still executing. Please wait till the results show up", "error", 5000);
        }
    }

    render() {
        return (
            <div className="Users-root center-block">
                <div className="User-query center-block row">
                    <a href='/'><span className="col-md-3 App-logo"/></a>
                    <form onSubmit={this.sendRequest}>
                        <input className='user-input col-md-5'
                               value={this.state.string}
                               ref={(search) => this.search = search}
                               placeholder="To submit query press Enter or Search button..."
                               onChange={event => this.handleInput(event)}/>
                        <button className='col-md-1'
                                onClick={this.handleSearch}>Search</button>
                        <button className='col-md-2'
                                onClick={event => this.clearSelection(event)}>Clear selection</button>
                    </form>
                </div>
                <div>
                    <Commands isSuperAdmin={this.props.isSuperAdmin}
                              updateUser={this.state.updateUser}
                              handleUpdate={users => this.props.handleUpdate(users)}/>
                    <UserTable onCompanySelect={operators => this.setState({companies: operators})}
                               onPermissionSelect={permission => this.setState({permission: permission})}
                               updateUser={user => this.setState({user: user, updateUser: true})}
                               companies={this.state.companies}
                               operators={this.state.operators}
                               permission={this.state.permission}
                               permissions={this.state.permissions}
                               users={this.props.users}
                               handleUpdate={users => this.props.handleUpdate(users)}
                               isSuperAdmin={this.props.isSuperAdmin}
                    />
                </div>
                {this.state.updateUser &&
                <Users user={this.state.user}
                       isSuperAdmin={this.props.isSuperAdmin}
                       handleUpdate={(users) => this.props.handleUpdate(users)}
                       onClose={() => this.setState({updateUser: false})}/>
                }
            </div>
        );
    }
}

class Commands extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addUser: false,
            statistics: false
        }
    }

    download(end){
        let url = `${properties.apiUrl}/api/user/${end}`;
        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        axios.get(url, config)
            .then(response => {
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1];
                FileDownload(response.data, filename, filename);
            })
            .catch(error => {
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    alert(error)
                }
            })
            .finally(() => this.setState({
                triedToSubmit: false,
                submitInProgress: false
            }));
    }

    render() {
        return (
            <div className='commands'>
                <a style={{marginLeft:10}} onClick={() => this.setState({addUser: true})}>
                    Add User
                </a>
                <a  style={{marginLeft: 10}}  onClick={() => this.download('export')}>
                    Export users
                </a>
                <a  style={{marginLeft: 10}}  onClick={() => this.setState({UsageRequest: true})}>
                    Usage report
                </a>
                {this.props.isSuperAdmin && <a style={{marginLeft: 10}} onClick={() => this.setState({QuieriesRequest: true})}>
                    Queries report
                </a>}

                <a  style={{marginLeft: 10}}  onClick={() => this.download('stats')}>
                    Get User Statistics
                </a>

                {this.state.addUser &&
                    <Users onClose={() => this.setState({addUser: false})}
                           handleUpdate={users => this.props.handleUpdate(users)}
                           isSuperAdmin={this.props.isSuperAdmin}/>
                }
                {this.state.UsageRequest &&
                    <Report onClose={() => this.setState({UsageRequest: false})}
                            title={'Usage report'}
                            endpoint={"report"}

                    />
                }
                {this.state.QuieriesRequest &&
                    <Report onClose={() => this.setState({QuieriesRequest: false})}
                            title={'Query report'}
                            endpoint={"queries"}

                    />
                }

            </div>
        );
    }
}

class UserRow extends React.Component {

    state = {};

    handleDelete() {
        axios.get(`${properties.apiUrl}/api/user/remove?email=${this.props.user.email}`, Auth.createConfig())
            .then((json) => {
                alert(`User ${this.props.user.email} was successfully deleted`);
                this.props.handleUpdate(json.data);
            })
            .catch(error => {
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    alert(error)
                }
            })
            .finally(() => this.setState({
                triedToSubmit: false,
                submitInProgress: false
            }));
    }

    render() {
        return (
            <tr>
                <td className='text-center'>
                    {this.props.index}
                </td>
                <td>
                    {this.props.user.firstName} {this.props.user.lastName}
                </td>
                <td>
                    {this.props.user.company}
                </td>
                <td>
                    {this.props.user.phone}
                </td>
                <td>
                    {this.props.user.email}
                </td>
                <td>
                    {this.props.user.position}
                </td>
                <td>
                    {this.props.user.location}
                </td>
                <td className="text-center">
                    { this.props.user.permissions ? this.props.user.permissions.includes(properties.permission_regular) ? '' : this.props.user.permissions.join(', ') : ''}
                </td>
                <td className="text-center">
                    <Image onClick={() => this.props.updateUser(this.props.user)} src="/images/edit-user-blue.png"/>
                    <Image onClick={() => this.handleDelete()} src="/images/delete-user-red.png"/>
                </td>
            </tr>
        );
    }
}

class UserTable extends React.Component {

    state = {};

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.users !== this.props.users) {
            this.generateRows();
        }
    }

    check(event) {
        let array = [];
        if (event.target.name === 'operator') {
            array = this.checkSelection(event.target.value)
        } else if (event.target.name === 'permission') {
            console.log(event.target.value)
            array = this.checkSelection(event.target.value)
        }
        return array;
    }

    handleSelection(event) {
        if (event.target.name === 'operator') {
            this.props.onCompanySelect(this.check(event));
        } else if (event.target.name === 'permission') {
            this.props.onPermissionSelect(this.check(event));
        }
    }

    checkSelection(selected) {
        if (selected.indexOf('all-operators') > -1) {
            return this.props.operators
        }
        if (selected.indexOf('all-permissions') > -1) {
            return this.props.permissions
        } else if (selected.indexOf('none') > -1) {
            return [];
        }
        return selected;
    }

    createSelectItems = (selection, full, val) => {
        let items = [];
        if (full) {
            items.push(
                <MenuItem key={0} value={val}>
                    <ListItemText>
                        <strong style={{fontSize: 12}}>Select all</strong>
                    </ListItemText>
                </MenuItem>);
            items.push(<MenuItem key={1} value='none'>
                <ListItemText>
                    <strong style={{fontSize: 12}}>Deselect all</strong>
                </ListItemText>
            </MenuItem>);
            items.push(<Divider variant="middle"/>);
            for (let i = 0; i < full.length; i++) {
                items.push(<MenuItem key={i + 2} value={full[i]}>
                    <Checkbox checked={selection.indexOf(full[i]) > -1}/>
                    <ListItemText primary={full[i]}/>
                </MenuItem>);
            }
        }
        return items;
    };

    generateRows() {
        let rows = this.props.users.map((v, i) =>
            <UserRow key={v} index={i + 1} user={v} handleUpdate={(users) => this.props.handleUpdate(users)}
                     updateUser={(user) => this.props.updateUser(user)}
                     isSuperAdmin={this.props.isSuperAdmin}/>);
        this.setState({
            rows: rows
        })
    }

    render() {
        return (
            <Table striped bordered condensed hover className="Items">
                <thead>
                <tr>
                    <th className='text-center'>#</th>
                    <th className='text-center'>User</th>
                    <th style={{width: '10vw'}} className='text-center'>
                        {this.props.isSuperAdmin &&
                            <div>
                                <InputLabel htmlFor="select-company">Company</InputLabel>
                                <Select multiple
                                        style={{width: '8vw'}}
                                        value={this.props.companies}
                                        id="select-company"
                                        name='operator'
                                        onChange={event => this.handleSelection(event)}
                                        renderValue={selected => selected.join(', ')}>
                                    {this.createSelectItems(this.props.companies, this.props.operators, 'all-operators')}
                                </Select>
                            </div>
                        }
                        {!this.props.isSuperAdmin &&
                            <div>
                                Company
                            </div>
                        }
                    </th>
                    <th className='text-center'>Phone</th>
                    <th className='text-center'>Email</th>
                    <th className='text-center'>Position</th>
                    <th className='text-center'>Location</th>
                    <th className='text-center'>
                        <InputLabel htmlFor="select-multiple">Permissions</InputLabel>
                        <Select multiple
                                style={{width: '11.5vw'}}
                                name='permission'
                                value={this.props.permission}
                                id='select-multiple'
                                onChange={event => this.handleSelection(event)}
                                renderValue={selected => selected.join(', ')} >
                            {this.createSelectItems(this.props.permission, this.props.permissions, 'all-permissions')}
                        </Select>
                    </th>
                    <th style={{width: 60}}>
                        <Image src='/images/menu-horizontal.png' />
                    </th>
                </tr>
                </thead>
                <tbody>{this.state.rows}</tbody>
            </Table>
        );
    }
}

class UserManagement extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isSuperAdmin: true,
            isAdmin: true,
            users:[]
        }
    }

    componentDidMount() {
        axios.get(`${properties.apiUrl}/api/validate_permission?permission=superadmin`, Auth.createConfig())
            .then(json => {
                this.setState({isSuperAdmin: true})
            })
            .catch(error => {
                console.log(error.response.data);
                this.setState({isSuperAdmin: false});
                axios.get(`${properties.apiUrl}/api/validate_permission?permission=admin`, Auth.createConfig())
                    .then(json => {
                        this.setState({isAdmin: true})
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        this.setState({isAdmin: false})
                    });
            });
        axios.get(`${properties.apiUrl}/api/user/get_all`, Auth.createConfig())
            .then(json => {
                this.setState({users: json.data, filtered: json.data, fullUsers: this.getFullInfo(json.data)});
            })
            .catch(error => {
                this.setState({
                    users: [], filtered: []
                });
                console.log(error)
            });
    }

    getFullInfo(users) {
        for (let i in users) {
            if(users[i].permissions) {
                if (users[i].permissions.length === 0) {
                    users[i].permissions = ['without']
                }
                users[i].permissions = permissionMapper(users[i].permissions)
            }
        }
        return users.map((user) => Object.values(user).join(',').toUpperCase())
    }

    handleUpdate(users) {
        this.setState({users: users, fullUsers: this.getFullInfo(users)});
        if (this.state.lastString || this.state.lastCompanies || this.state.lastPermissions) {
            this.search(this.state.lastString, this.state.lastCompanies, this.state.lastPermissions)
        } else {
            this.setState({filtered: users})
        }
    }

    checkInArray(array, userInfo) {
        for (let i in array) {
            if (userInfo.includes(array[i].toUpperCase())) {
                return true
            }
        }
        return false;
    }

    checkInWords(words, userInfo) {
        let all = true;
        for (let i in words) {
            if (userInfo.includes(words[i].toUpperCase())) {
                all = all && true
            } else {
                all = false
            }
        }
        return all;
    }

    checkUser = (user, string, companies, permissions) => {
        let words = string.split(' ');
        let shouldShow = true;
        if (string) {
            shouldShow = this.checkInWords(words, user);
        }
        if (companies) {
            shouldShow = shouldShow && this.checkInArray(companies, user)
        }
        if (permissions) {
            shouldShow = shouldShow && this.checkInArray(permissions, user)
        }
        return shouldShow;
    };

    search(string, companies, permissions) {
        let filtered = [];
        if (string === '' && companies === 'all'&& permissions === 'all') {
            filtered = this.state.users;
        } else {
            filtered = _.filter(this.state.users, (element, index) => {
                return this.checkUser(this.state.fullUsers[index], string, companies, permissions)
            });
            console.log(filtered)
        }
        this.setState({
            filtered: filtered,
            lastString: string,
            lastCompanies: companies,
            lastPermissions: permissions
        })
    }

    render() {
        if (this.state.isAdmin || this.state.isSuperAdmin) {
            return (
                <div>
                    <Search isSuperAdmin={this.state.isSuperAdmin} users={this.state.filtered}
                            handleUpdate={users => this.handleUpdate(users)}
                            onSearch={(string, companies, permissions) => this.search(string, companies, permissions)}/>

                    <Menu history={this.props.history} contactUs={true}/>
                </div>
            );
        } else {
            return <Redirect to='/'/>
        }
    }
}

export default UserManagement;
