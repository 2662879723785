import React, { Component } from 'react';
import * as Auth from './AuthService';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import Menu from "./Menu";
import './Catalog.css';
import axios from "axios";
import {properties} from "./properties";
import Table from "react-bootstrap/lib/Table";
import {Tab, Tabs} from "react-bootstrap";

class Head extends Component {

    render() {

        let head = this.props.data;

        return (
            <div>
                <h4 className='catalog-header'><b>PART DESCRIPTION</b></h4>
                <div style={{marginTop:15, marginBottom: 15}}>
                      <b>DESCRIPTION:</b> {head.description}
                </div>
                <div className="row" style={{marginTop:10}}>
                    <div className="col-md-4">
                        <p><b>Part Number:</b> {head.part_number}</p>
                        {head.revision && <p><b>Revision:</b> {head.revision}</p>}
                        {head.ecn && <p><b>ECN:</b> {head.ecn}</p>}
                        {head.um && <p><b>UOM:</b> {head.um}</p>}
                        {head.weight &&<p><b>Weight:</b> {head.weight}</p>}
                    </div>
                    <div className="col-md-4">
                        <p><b>Drawing No:</b> <a className='catalog-dwg-number' href='#'>{head.dwg_number}</a></p>
                        {head.dwg_revision && <p><b>Drawing Revision:</b> {head.dwg_revision}</p>}
                        {head.dwg_own && <p><b>DWG Own:</b> {head.dwg_own}</p>}
                    </div>
                    <div className="col-md-4">
                        {head.type && <p><b>Type:</b> {head.type}</p>}
                        {head.status && <p><b>Status:</b> {head.status}</p>}
                        {head.ownership && <p><b>Ownership:</b> {head.ownership}</p>}
                    </div>
                </div>
            </div>
        );
    }

}

class ChildRow extends Component {

    render() {

        let child = this.props.child;

        let old = this.props.oldKey === child.key ? "#fcf6d7" : ""

        return (
            <tr style={{backgroundColor: old}}  id={`#${child.key.replace(/-/g,'_')}`}>
                <td className="text-center">{this.props.index + 1}</td>
                <td className='catalog-link' onClick={() => this.props.openChild()}>{child.part_number}</td>
                <td>{child.description}</td>
                <td> <a className='catalog-link' href='#'>{child.dwg_number}</a></td>
                <td className="text-center">{child.um}</td>
                <td className="text-center">{child.qty}</td>
                <td>{child.material}</td>
            </tr>
        );
    }

}

class ChildTable extends Component {

    render() {

        let rows = this.props.childs.map((v,i) => <ChildRow key={i}
                                                            index={i}
                                                            oldKey={this.props.oldKey}
                                                            openChild={() => this.props.openChild(v.head.key)}
                                                            child={v.head}/>);
        if (rows.length === 0){
            rows.push(<tr><td className='text-center' colSpan='7'>Doesn't have</td></tr>)
        }

        return (
            <div>
                <Table striped bordered condensed hover className="Items">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Part Number</th>
                        <th>Description</th>
                        <th>DWG Number</th>
                        <th>UM</th>
                        <th>QTY</th>
                        <th>Material</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>
        );
    }

}

class ParentRow extends Component {

    render() {

        let child = this.props.child;
        let old = this.props.oldKey === child.key ? "#fcf6d7" : ""

        return (
            <tr style={{backgroundColor: old}}>
                <td>{this.props.index + 1}</td>
                <td onClick={() => this.props.openChild()} className='catalog-link'>{child.part_number}</td>
                <td>{child.description}</td>
                <td> <a className='catalog-link' href='#'>{child.dwg_number}</a></td>
            </tr>
        );
    }

}

class ParentTable extends Component {

    render() {

        let rows = this.props.parents.map((v,i) => <ParentRow key={i}
                                                            index={i}
                                                              oldKey={this.props.oldKey}
                                                            openChild={() => this.props.openChild(v.key)}
                                                            child={v}/>);
        if (rows.length === 0){
            rows.push(<tr><td className='text-center' colSpan='7'>Doesn't have</td></tr>)
        }

        return (
            <div>
                <Table striped bordered condensed hover className="Items">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Part Number</th>
                        <th>Description</th>
                        <th>DWG Number</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>
        );
    }

}

class CommonalityRow extends Component {

    render() {

        let child = this.props.child;

        return (
            <tr>
                <td>{this.props.index + 1}</td>
                <td>{child.operator} {child.material}</td>
                <td>{child.description}</td>
                <td onClick={() => this.props.openChild()} className='catalog-link'>{child.part_number_original}</td>
                <td>{child.stock_level}</td>
            </tr>
        );
    }

}

class CommonalityTable extends Component {

    render() {

        let rows = this.props.matched.map((v,i) => <CommonalityRow key={i}
                                                              index={i}
                                                              openChild={() => this.props.openChild(v.material)}
                                                              child={v}/>);
        if (rows.length === 0){
            rows.push(<tr><td className='text-center' colSpan='7'>Doesn't have</td></tr>)
        }

        return (
            <div>
                <Table striped bordered condensed hover className="Items">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Material</th>
                        <th>Description</th>
                        <th>Part Number</th>
                        <th>Stock Level</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>
        );
    }

}

class PartData extends React.Component {

    render() {

        let head = this.props.head;

        return (
            <div>
                <h4 className='text-center'>Part Data</h4>
                <div className='row'>
                    <div className='col-md-6'>
                        <p><b>Prod Spec:</b> {head.prod_spec}</p>
                        <p><b>Matl Class:</b> {head.matl_class}</p>
                        <p><b>PR#:</b> {head.pr}</p>
                        <p><b>GR#:</b> {head.gr}</p>
                        <p><b>Approved By:</b> {head.approved_by}</p>
                        <p><b>Reviewed By:</b> {head.review_by}</p>
                    </div>
                    <div className='col-md-6'>
                        <p><b>Quality lvl:</b> {head.quality_lvl}</p>
                        <p><b>Temp Range:</b> {head.temp_range}</p>
                        <p><b>Srv Class:</b> {head.srv_class}</p>
                        <p style={{color:'white'}}>123</p>
                        <p><b>Approve Date:</b> {head.approval_date}</p>
                        <p><b>Reviewe Date:</b> {head.review_date}</p>
                    </div>
                </div>
            </div>
        );
    }

}


export class Catalog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            head: {},
            childs: [],
            key: 0,
            oldKey: '',
            parents: [],
            show: false,
            matchedRows: []
        }
    }

    scroll() {
        if (this.state.oldKey) {
            let el = document.getElementById(`#${this.state.oldKey.replace(/-/g, '_')}`)
            if (el) {
                el.scrollIntoView(true)
            }
        }
    }

    componentDidMount(): void {
        axios.get(`${properties.apiUrl}/api/catalog/get?key=${sessionStorage.getItem("catalog_item")}`, Auth.createConfig())
            .then(json => {
                if (json.data.childs.length === 0 && json.data.parents.length === 1) {
                    this.openChild(json.data.parents[0].key)
                } else {
                    this.setState(json.data)
                    this.sendRequest()
                    if (json.data.parents.length < 3) {
                        this.setState({show: true})
                    }
                }
            })
            .catch(error => console.log(error))

    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS): void {
        if(prevState.childs !== this.state.childs) {
            this.setState({show: false})
        }
        if(prevState.oldKey !== this.state.oldKey) {
            this.scroll();
        }
    }

    openChild(key) {
        axios.get(`${properties.apiUrl}/api/catalog/get?key=${key}`, Auth.createConfig())
            .then(json => {
                this.setState(json.data)
                this.setState({key:0, matchedRows: []})
                this.setState({oldKey: sessionStorage.getItem("catalog_item")})
                sessionStorage.setItem("catalog_item", key)
                this.sendRequest(key)
                if(json.data.parents.length <3){
                    this.setState({show: true})
                }
            })
            .catch(error => console.log(error))
    }
    handleSelect(key) {
        this.setState({ key });
    }

    sendRequest(key = null){
        key = key ? key : sessionStorage.getItem("catalog_item")
        axios.get(`${properties.apiUrl}/api/search/report?operator=TechnipFMC&material=${key}&showAll=${true}`, Auth.createConfig())
            .then(json => {
                let matched = []
                for (let i = 0;  i<json.data.matched.length; i++) {
                    let row  = json.data.matched[i];
                    if (row.material !== key) {
                        matched.push(row)
                    }
                }
                this.setState({ matchedRows: matched});
            })
            .catch(error => {
                console.log(error);
                this.setState({matchedRows: []});
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                }
            });
    }

    render() {

        const open_icon = this.state.show ?
            <img src="/images/up.png" />
            : <img src="/images/down.png" onClick={() => this.setState({show: !this.state.show})}/>

        return (
            <div className='catalog-root center-block'>
                <div className="App-query center-block row">
                    <a href='/'><span className="col-md-3 App-logo"/></a>
                </div>
                <h3 className="text-center">PART REPORT</h3>
                {this.state.parents.length > 0 && <div onClick={() => this.setState({show: !this.state.show})} className='catalog-block'>
                    <h4 className='catalog-header'><b>PARENT INFO</b>{open_icon}</h4>
                    {this.state.show &&
                    <ParentTable parents={this.state.parents}
                                 oldKey={this.state.oldKey}
                                 openChild={(key) => this.openChild(key)}/>}
                </div>}
                <div className="catalog-block">
                    <Head data={this.state.head}/>
                </div>
                <div className="catalog-block">
                    <h4 className='catalog-header'><b>ADDITIONAL INFO</b></h4>
                <Tabs id="controlled-tab-example" activeKey={this.state.key}
                      onSelect={k => this.handleSelect(k)}>

                    <Tab eventKey={0} title="Bill of material">
                        <ChildTable childs={this.state.childs}
                                    oldKey={this.state.oldKey}
                                    openChild={(key) => this.openChild(key)}/>
                    </Tab>
                    <Tab eventKey={1} title={`Comonality report (${this.state.matchedRows.length})`}>
                        <CommonalityTable matched={this.state.matchedRows}
                                          openChild={(key) => this.openChild(key)}/>
                    </Tab>
                    <Tab eventKey={2} title="Part Data">
                        <PartData head={this.state.head}/>
                    </Tab>
                    <Tab eventKey={3} title="Requirements Matrix">
                        <div className='text-center'>Under construction</div>
                    </Tab>
                    <Tab eventKey={4} title="Engineering specifications">
                        <div className='text-center'>Under construction</div>
                    </Tab>
                    <Tab eventKey={5} title="Layout List">
                        <div className='text-center'>Under construction</div>
                    </Tab>
                    <Tab eventKey={6} title="Document List">
                        <div className='text-center'>Under construction</div>
                    </Tab>
                </Tabs>
                </div>
                <Menu history={this.props.history}/>
            </div>
        );
    }

}
