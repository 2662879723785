import 'react-app-polyfill/ie9';
import 'url-search-params-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Admin from './Admin';
import Users from './Users';
import Login from './Login';
import * as serviceWorker from './serviceWorker';
import * as Auth from './AuthService';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import UserManagement from "./UserManagement";
import Account from "./Account";
import PasswordReset from "./PasswordReset";
import PasswordCreate from "./PasswordCreate";
import UploadComp from "./Upload";
import {Catalog} from "./Catalog";
import {FullData} from "./FullData";
import GlobalSearchAdmin from "./globalSearch";
import axios from "axios";

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path='/login'
                   render={props => Auth.loggedIn() ? <Redirect to={Auth.extractRedirect()}/> : <Login {...props}/>}
            />
          <Route path='/global-search'
                 render={props => Auth.loggedIn() ? <GlobalSearchAdmin {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
          />
            <Route path='/password-change*'
                   render={props => <PasswordReset />}
            />
            <Route path='/data*'
                   render={props => <FullData {...props}/>}
            />
            <Route path='/password-create*'
                   render={props => <PasswordCreate />}
            />
            <Route path='/upload'
                   render={props => Auth.loggedIn() ? <UploadComp {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/admin'
                   render={props => Auth.loggedIn() ? <Admin {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/user'
                   render={props => Auth.loggedIn() ? <Account {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/users'
                   render={props => Auth.loggedIn() ? (Auth.getUser().can_open ? <UserManagement {...props}/> : <App {...props}/>) : <Redirect to={Auth.redirectToLogin()}/>}
            />
            <Route path='/*'
                   render={props => Auth.loggedIn() ? <App {...props}/> : <Redirect to={Auth.redirectToLogin()}/>}
            />
        </Switch>
    </BrowserRouter>,

    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
