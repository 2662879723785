export const properties = {
    apiUrl: process.env.REACT_APP_HOST,
    company: 'Company',
    showAll: true,
    email: 'Email',
    firstName: 'First Name',
    lastName: 'Last Name',
    phone: 'Phone',
    permissions: "Permissions",
    location: 'Location',
    position: 'Position',
    permission_admin:'System Administrator from Operator',
    permission_regular: 'Regular User',
    permission_operator: 'Nominated Contact Person',
    permission_read: 'Read Only',
    permission_internal : "Internal user",
    permission_super: 'Super Administrator',
    previewRowsPerPage: 10
};
