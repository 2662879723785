import React, {Component} from 'react';
import * as Auth from './AuthService';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap-theme.min.css';
import Menu from "./Menu";
import * as _ from 'lodash'
import {Modal, OverlayTrigger, Panel, PanelGroup, Tab, Table, Tooltip} from 'react-bootstrap';
import './Catalog.css';
import axios from "axios";
import {RequestModal} from "./App";
import {properties} from "./properties";
import Tabs from "react-bootstrap/lib/Tabs";
import { Line } from 'react-chartjs-2';
import {notify} from "react-notify-toast";
import FuzzySet from "fuzzyset.js";

const FileDownload = require('js-file-download');

class AdditionalInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            table: []
        }
    }

    createTable(info) {
        let table = <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th>Equipment</th>
                <th>Tag #</th>
                <th>Area</th>
                <th>KH Links</th>
            </tr>
            </thead>
            <tbody>{info.length > 0 ?
                info.map((v, i) => <tr key={i}>
                    <td>{v['Equipment']}</td>
                    <td>{v['Tag #']}</td>
                    <td>{v['Area']}</td>
                    <td>{v['links'].split(';').map((l, j) => l !== 'NA' ?
                        <a style={{marginRight: 10}} href={l} target="_blank" rel="noopener noreferrer"
                           key={j}>Link {j + 1}</a> : <div/>)}</td>
                </tr>)
                : []}</tbody>
        </Table>
        return table;
    }

    beautifyKey(key) {
        if (key === 'Equipment (number of)') {
            return 'Equipment (number of)'
        }
        return _.startCase(key.split('_').join(" "))
    }

    filter(item, element) {
        let arr = ['raw', 'attrs', 'url', 'Unnamed: 0']
        return item[element] && item[element] !== 'NA' && arr.indexOf(element) === -1
    }

    getEquipmentTable(operator, material) {
        if (this.state.table.length === 0) {
            axios.get(`${properties.apiUrl}/api/get/equipment?operator=${operator}&material=${material}`, Auth.createConfig())
                .then(json => {
                    this.setState({open: true, table: this.createTable(json.data['equipment'])})

                })
                .catch(error => console.log(error))
        } else {
            this.setState({open: !this.state.open})
        }
    }

    getValue(v, item) {
        if (v === 'Equipment (number of)' && item[v] !== "NA") {
            return this.state.open ? <div>
                    <div style={{color: "blue"}} onClick={() => this.getEquipmentTable(item['operator'], item['material'])}>
                        {item[v]}
                    </div>
                    <div style={{
                        border: '1px solid blue',
                        color: 'black',
                        width: '100%',
                        maxHeight: '250px',
                        overflowY: 'auto'
                    }}>
                        {this.state.table}</div>
                </div> :
                <div style={{color: "blue"}} onClick={() => this.getEquipmentTable(item['operator'], item['material'])}>
                    {item[v]}
                </div>
        }
        return <div>{item[v]}</div>
    }

    render() {

        let item = this.props.item;
        let columns = Object.keys(item);
        let mainColumns = this.props.mainColumns;

        let filtered = _.filter(columns, (element) => {
            return mainColumns.indexOf(element) === -1 && this.filter(item, element)
        });


        let rows = filtered.map((v, i) => <div className="row">
            <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
            <div className='col-md-10'>
                {v === 'stock_level' ?
                    <div onClick={() => this.props.request()}>{item[v]}</div> :
                    <div>
                        {this.getValue(v, item)}</div>}</div>
        </div>)

        if (rows.length === 0) {
            return <div/>
        }

        return (
            <Panel bsStyle="primary">
                <Panel.Body>
                    <h4><b>Additional info</b></h4>
                    {rows}
                </Panel.Body>
            </Panel>
        );
    }

}

class AddImageModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            progress: '',
            activeKey: '1',
            old: [],
            order: [],
            actives: []
        }

    }

    componentDidMount() {
        this.get_ids()
    }

    setAsActive(i) {

        let data = {
            _id: this.state.order[i]
        }

        axios.post(`${properties.apiUrl}/api/image/active`, data, Auth.createConfig())
            .then(response => this.props.onClose())
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }
            })

    }

    deleteImage(i) {

        let data = {
            _id: this.state.order[i]
        }

        axios.post(`${properties.apiUrl}/api/image/delete`, data, Auth.createConfig())
            .then(response => {
                alert('The image was successfully deleted')
                this.get_ids()
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }
            })

    }

    getImgById(id) {
        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        let data = {
            _id: id
        }

        axios.post(`${properties.apiUrl}/api/image/id`, data, config)
            .then(response => {
                let matrixBlob = new Blob([response.data], {type: "image/jpg"});
                let fileReader = new FileReader();
                const header = response.headers['content-disposition'];
                const filename = /filename=(.*)/.exec(header)[1].replace(/"/g, '');
                fileReader.readAsDataURL(matrixBlob);
                fileReader.onload = () => {
                    let result = fileReader.result;
                    this.setState((state) => ({
                        old: state.old.concat(<img style={{maxHeight: 150}} alt={'img-' + id} src={result}/>),
                        order: state.order.concat(id),
                        actives: state.actives.concat(filename === 'True' ? 'active' : '')
                    }));
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }
            })
    }

    get_ids() {

        let data = {
            material: this.props.item.material,
            type: 'img',
            operator: this.props.item.operator,
            part_number: this.props.item.part_number
        }

        axios.post(`${properties.apiUrl}/api/images`, data, Auth.createConfig())
            .then(response => {
                this.setState({ids: response.data, old: [], order: [], actives: []});
                for (let id in response.data) {
                    this.getImgById(response.data[id])
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }
            })

    }

    downloadImage(i) {

        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        let item = this.props.item

        let data = {
            _id: this.state.order[i]
        }

        axios.post(`${properties.apiUrl}/api/image/id`, data, config)
            .then(response => {
                const filename = `${item.operator}-${item.material}-${item.part_number}.jpg`
                FileDownload(response.data, filename, filename);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }

            })
    }

    handleSelect(activeKey) {
        this.setState({activeKey});
    }

    uploadFile(e) {
        var formData = new FormData();
        var input = e.target;
        if (input.files.length > 0) {
            for (let file of input.files) {
                formData.append(file.name, file);
            }
            const config = Auth.createConfig();
            config['headers']['Content-Type'] = 'multipart/form-data';
            config['onUploadProgress'] = progressEvent => {
                this.setState({progress: progressEvent.loaded < progressEvent.total ? 'Uploading files...' : 'Processing files...'});
            };
            axios
                .post(`${properties.apiUrl}/api/upload/image?operator=${this.props.item.operator}&material=${this.props.item.material}&part_number=${this.props.item.part_number}`, formData, config)
                .then(resp => {
                    if (resp.data.message) {
                        alert(resp.data.message)
                    } else {
                        alert('Your files were successfully uploaded!')
                    }
                    this.props.onClose()
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        alert('Your session expired! Please login again!');
                        Auth.logout();
                        this.props.history.replace(Auth.redirectToLogin());
                    } else {
                        alert(error.response.data)
                    }
                })
                .finally(() => {
                    input.value = '';
                    this.setState({progress: ''});
                });
        }
    }

    render() {

        let rows = this.state.old.map((v, i) =>
            <tr style={{backgroundColor: this.state.actives[i] === 'active' ? '#ccffcc' : ''}}>
                <td>{v}</td>
                <td>
                    <p><a onClick={() => this.setAsActive(i)}>Set as active</a></p>
                    <p><a onClick={() => this.downloadImage(i)}>Download</a></p>
                    <p><a onClick={() => this.deleteImage(i)}>Delete</a></p>
                </td>
            </tr>
        )

        return (
            <Modal show={this.props.show} onHide={() => this.props.onClose()} onClose={() => this.props.onClose()}>
                <Modal.Header bsStyle='primary' closeButton>
                    <Modal.Title>Image Editor</Modal.Title>
                </Modal.Header>

                <PanelGroup
                    accordion
                    style={{padding: 5}}
                    id="accordion-controlled-example"
                    activeKey={this.state.activeKey}
                    onSelect={(activeKey) => this.handleSelect(activeKey)}
                >

                    <Panel eventKey="1">
                        <Panel.Heading>
                            <Panel.Title toggle>Upload image by file</Panel.Title>
                        </Panel.Heading>
                        <Panel.Body collapsible>
                            <input type="file"
                                   onChange={e => this.uploadFile(e)}
                                   multiple
                                   disabled={this.state.progress !== ''}/>
                        </Panel.Body>
                        {/*<Panel eventKey="2">*/}
                            {rows.length > 0 && <Panel eventKey="3">
                                <Panel.Heading>
                                    <Panel.Title toggle>Select previously uploaded image</Panel.Title>
                                </Panel.Heading>
                                <Panel.Body collapsible>
                                    <Table bordered condensed hover className='text-center'>
                                        <thead>
                                        <tr>
                                            <th className='text-center'>Image</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {rows}
                                        </tbody>
                                    </Table>
                                </Panel.Body>
                            </Panel>}
                        {/*</Panel>*/}
                    </Panel>
                </PanelGroup>

            </Modal>
        );
    }

}

class MainInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            links: [],
            showModal: false,
            open: false,
            table: [],
            trOpen: false,
            key: 0,
            hers: [],
            structured: [],
            usage: []
        }
        this.handleSelect = this.handleSelect.bind(this);
    }

    createTable(info) {
        let table = <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th>Equipment</th>
                <th>Tag #</th>
                <th>Area</th>
                <th>KH Links</th>
            </tr>
            </thead>
            <tbody>{info.length > 0 ?
                info.map((v, i) => <tr key={i}>
                    <td>{v['Equipment']}</td>
                    <td>{v['Tag #']}</td>
                    <td>{v['Area']}</td>
                    <td>{v['links'].split(';').map((l, j) => l !== 'NA' ?
                        <a style={{marginRight: 10}} href={l} target="_blank" rel="noopener noreferrer"
                           key={j}>Link {j + 1}</a> : <div/>)}</td>
                </tr>)
                : []}</tbody>
        </Table>
        return table;
    }

    createRows (info, months) {
        const rows = []
        const years = ['2021', '2020', '2019', '2018', '2017', '2016']
        for (let i in years) {
            let row = info.find(x => x.year === years[i])
            if (row) {
                rows.push(<tr>
                    <td className='text-center'>{years[i]}</td>
                    <td className='text-center'>{row['total']}</td>
                    {months.map((v,j) => <td className='text-center' key={j}>{row[v]}</td>)}
                </tr>)
            } else {
                rows.push(<tr>
                    <td className='text-center'>{years[i]}</td>
                    <td />
                    {months.map((v,j) => <td key={j} />)}
                </tr>)
            }
        }

        return rows
    }

    createUsageTable(info, months) {

        let table = <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th>Year</th>
                <th>Total</th>
                {months.map((v,i) => <th key={i}>{v}</th>)}
            </tr>
            </thead>
            <tbody>{info.length > 0 ?
                this.createRows(info, months)
                : []}</tbody>
        </Table>
        return table;
    }

    substr(description, part_number){
        let value = description;
        const from = value.indexOf(part_number.trim());
        const length = part_number.trim().length;
        if (from > -1){
            value = value.substr(0, from) + '<strong>' + value.substr(from, length) + '</strong>' + value.substr(from + length);
        }

        return value
    }


    generatorMatched(item, i) {

        let f = FuzzySet(item.description.split(','));
        let pn_original = f.get(item.part_number_original.trim()) ? f.get(item.part_number_original.trim())[0][1] : '';
        let pn_matched = f.get(item.part_number_matched.trim()) ? f.get(item.part_number_matched.trim())[0][1] : '';
        let descr = this.substr(item.description, pn_original);
        descr = this.substr(descr, pn_matched);
        item.url = this.props.item.url;
        return <tr>
            <td>
                {i +1}
            </td>
            <td className="text-center">
                <span className="Item-material ">
                    <a href={`/data?operator=${item.operator}&material=${item.material}`}
                       target="_blank"
                       rel="noopener noreferrer">
                        {item.operator} {item.material}
                    </a>
                </span>
            </td>
            <td style={{wordBreak: 'break-all'}}>
                <span dangerouslySetInnerHTML={{__html: descr}}>
                </span>
            </td>
            <td className="Item-part-number">
                <a href={`https://www.google.com/search?q=${item.part_number_original}`} target="_blank"
                   rel="noopener noreferrer">
                    {item.part_number_original}
                </a>

            </td>
            <td className="text-center">
                <div onClick={() => this.props.onlyView ? null : null}>{item.stock_level} {item.unit}</div>
            </td>
        </tr>;
    }

    createCommonalityTable() {
        const matches = this.props.matchedRows;
        const item = this.props.item;
        const rows = []
        let idx = 0
        if (matches.length > 0) {
            for (let i = 0; i < matches.length; i++) {
                if(!(String(item.material) === matches[i].material && item.operator === matches[i].operator)){
                    rows.push(this.generatorMatched(matches[i], idx))
                    idx += 1;
                }
            }
        }
        return  <Table className='Items' bordered condensed hover style={{margin: 0}}>
            <thead>
            <tr>
                <th>#</th>
                <th>Material</th>
                <th>Description</th>
                <th>Part Number</th>
                <th>Stock Level</th>
            </tr>
            </thead>
            <tbody>{rows}</tbody>
        </Table>
    }

    createTrTable(info) {
        let table = <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th>Posting Date</th>
                {/*<th>Time Of Entry</th>*/}
                <th>Movement Type</th>
                <th>Quantity</th>
                <th>Movement Type Text</th>
            </tr>
            </thead>
            <tbody>{info.length > 0 ?
                info.map((v, i) => <tr key={i}>
                    <td>{v['Posting Date'].split(' ')[0]}</td>
                    {/*<td>{v['Time of Entry']}</td>*/}
                    <td>{v['Movement Type']}</td>
                    <td>{v['Quantity']}</td>
                    <td>{v['Movement Type Text']}</td>
                </tr>)
                : []}</tbody>
        </Table>
        return table;
    }

    getTransactionsChartData() {

        const tr = this.props.transactions.slice(0, this.props.transactions.length).reverse()

        return {
            labels: tr.map((v,i) => v['Posting Date'].split(' ')[0]),
            datasets: [
                {
                    label: 'SOH',
                    data: tr.map((v,i) => v['stock']),
                    borderColor: 'blue',
                    backgroundColor: 'rgba(0, 255, 128, 0)',
                    fill: false,
                    stepped: true,
                },
                {
                    label: 'ROP / MIN',
                    data: this.props.transactions.map((v,i) => this.props.item['ROP / MIN']),
                    // fill: false,
                    backgroundColor: 'rgba(0, 255, 128, 0)',
                    borderColor: 'rgba(0, 255, 128, 0.2)',
                },
                {
                    label: 'MAX',
                    data: this.props.transactions.map((v,i) => this.props.item['MAX']),
                    fill: false,
                    backgroundColor: 'rgba(54, 162, 235,0)',
                    borderColor: 'rgba(255, 0, 0, 0.3)',
                },
            ]
        };

    }

    getEquipmentTable(operator, material) {
        if (this.state.table.length === 0) {
            axios.get(`${properties.apiUrl}/api/get/equipment?operator=${operator}&material=${material}`, Auth.createConfig())
                .then(json => {
                    this.setState({open: true, table: this.createTable(json.data['equipment'])})

                })
                .catch(error => console.log(error))
        } else {
            this.setState({open: !this.state.open})
        }
    }

    getUsageStatTable(operator, material) {
        if (this.state.usage.length === 0) {
            axios.get(`${properties.apiUrl}/api/get/stats?operator=${operator}&material=${material}`, Auth.createConfig())
                .then(json => {
                    this.setState({usage: this.createUsageTable(json.data['stats'], json.data['months'])})

                })
                .catch(error => console.log(error))
        }
    }

    getSOHValue(soh) {
        if (typeof soh === 'string'){
            if (soh && soh.split('.')[1] != 0 )
            return soh
        }
        return parseInt(soh)
    }

    getSOHTable() {
        const item = this.props.item
        const items = this.props.locationsData;



        if (items) {
            const rows = items.map((v, i) => <tr className='text-center' key={i}>
                <td />
                <td>{v['Plant']}</td>
                <td>{v['location']}</td>
                <td>{v['Bin Location']}</td>
                <td>{this.props.item['unit'] === 'NA' ? '' : this.props.item['unit']}</td>
                <td>{this.getSOHValue(v['stock_level'])}</td>
                <td>{this.getSOHValue(v['surplus_level'])}</td>
                <td>{this.getSOHValue(v['MIN']) || ''}</td>
                <td>{this.getSOHValue(v['MAX']) || ''}</td>
                <td>{this.getSOHValue(v['MIN']) || ''}</td>
                <td>{v['MRP Type']}</td>
                <td>{v['lead_time']}</td>
            </tr>)

            let max = items.map(v => parseInt(v.MAX)).reduce((a, b) => a + b, 0)
            let min = items.map(v => parseInt(v['MIN'])).reduce((a, b) => a + b, 0)
            let surplus = items.map(v => parseInt(v['surplus_level'])).reduce((a, b) => a + b, 0)

            rows.push(
                <tr className='text-center'>
                    <td><b>Total #</b></td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{item.stock_level}</td>
                    <td>{surplus || ''}</td>
                    <td>{min || ''}</td>
                    <td>{max || ''}</td>
                    <td />
                    <td />
                    <td />
                </tr>
            )
            rows.push(
                <tr className='text-center'>
                    <td><b>Total $</b></td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{item.stock_level * parseFloat(item['Unit Pirce in USD']) || ''}</td>
                    <td>{surplus * parseFloat(item['Unit Pirce in USD']) || '' }</td>
                    <td>{min * parseFloat(item['Unit Pirce in USD']) || ''}</td>
                    <td>{max * parseFloat(item['Unit Pirce in USD']) || ''}</td>
                    <td />
                    <td />
                    <td />
                </tr>
            )

            return <Table  striped bordered condensed hover className="Items">
                <thead>
                <tr>
                    <th />
                    <th>Plant</th>
                    <th>Location</th>
                    <th>Bin Location</th>
                    <th>UOM</th>
                    <th>Stock on Hand</th>
                    <th>Surplus</th>
                    <th>Min</th>
                    <th>Max</th>
                    <th>ROP</th>
                    <th>MRP Type</th>
                    <th>Lead Time (days)</th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </Table>
        }
    }

    getHers() {
        axios.get(`${this.props.item.url}/api/hers/full-data?operator=${this.props.item.operator}&material=${this.props.item.material}`, Auth.createConfig())
            .then(json => {
                this.setState({hers: json.data['records']})
            })
            .catch(error => console.log(error))
    }

    getStructured() {
        axios.get(`${this.props.item.url}/api/structured/full-data?operator=${this.props.item.operator}&material=${this.props.item.material}`, Auth.createConfig())
            .then(json => {
                this.setState({structured: json.data['records'], key: json.data['records'].length > 0 ? 0 : 11})
            })
            .catch(error => console.log(error))
    }

    componentDidMount() {
        let operator = new URLSearchParams(window.location.search).get('operator');
        let material = new URLSearchParams(window.location.search).get('material');
        axios.get(`${properties.apiUrl}/api/get/khlinks?operator=${operator}&material=${material}`, Auth.createConfig())
            .then(json => {
                this.setState({links: json.data['links']})
                this.getEquipmentTable(operator, material)
            })
            .catch(error => console.log(error))
        this.getUsageStatTable(operator, material)

    }

    beautifyKey(key) {
        if (key === 'cl_name')
            return 'Material Class'
        if (key === 'filename')
            return ''
        else if (key == 'Unit Pirce in USD') {
            return 'Price Per Unit (USD)'
        } else if (key == 'demand') {
            return 'Demand Units PA'
        } else if (key === 'Transactions PA (ave.)') {
            return 'Transactions PA (ave.)'
        }
        if (key === 'Equipment (number of)') {
            return 'Equipment (number of)'
        }
        if (key === 'No BOMs') {
            return 'No BOMs'
        }
        if (key === 'manuf_name') {
            return 'Manufacturer'
        }
        return _.startCase(key.split('_').join(" "))
    }

    createLocationsTable() {
        return <table className='text-center'>
            <tbody  className='text-center'>
            {this.props.locationsData.map((v, i) => <tr className='text-center' key={i}>
                <td>{v.stock_level} </td>
                <td style={{paddingLeft: 5}}> {v.location}</td>
            </tr>)}            </tbody>
        </table>
    }


    getValue(v, item) {
        if (v === 'part_number') {
            return <a href={`https://www.google.com/search?q=${item.part_number}`} target="_blank"
                      rel="noopener noreferrer">
                {item.part_number}
            </a>
        } else if (v === 'stock_level') {
            return <div className='catalog-link' onClick={() => this.props.request()}>{item[v]}</div>
        } else if (v === 'tag' || v == 'filename') {
            return null;
        } else if (v === 'location') {
            return this.createLocationsTable()
        } else if (v === 'demand') {
            return item.demand || 'NA'
        } else if (v === 'Transactions PA (ave.)') {
            return Math.round(item['Transactions PA (ave.)'])
        } else if (v === 'surplus_level') {
            return Math.round(item.Surplus) || 'NA'
        }
        if (v === 'Equipment (number of)' && item[v] !== "NA") {
            return <div style={{color: 'black', width: '100%', maxHeight: '250px', overflowY: 'auto'}}>
                {this.state.table}
            </div>

        }

        if (v === 'Num of Transactions' && this.props.transactions) {


            const options = {
                // responsive: true,
                interaction: {
                    intersect: false,
                    axis: 'x'
                },
            };


            return <div className='row'>
                <div className={'col-md-8'}>
                    <div style={{
                        // border: '1px solid blue',
                        color: 'black',
                        width: '95%',
                        marginLeft: 15,
                        display: 'inline-flex',
                        maxHeight: '250px',
                        overflowY: 'auto'
                    }}>
                        {this.createTrTable(this.props.transactions)}
                    </div>
                </div>
                <div className='col-md-4'>
                    <Line data={this.getTransactionsChartData()} options={options} />
                </div>

            </div>
        } else if (v.indexOf('link') > -1 && item[v] !== "NA") {
            return <a href={item[v]} target="_blank" rel="noopener noreferrer">
                Link
            </a>
        }
        return <div>{item[v]}</div>
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.item !== this.props.item && this.props.item) {
            this.getImage();
            this.getHers()
            this.getStructured()
        }
        if (prevState.show !== this.state.show && !this.state.show) {
            this.getImage();
        }
    }

    getImage() {
        const config = Auth.createConfig();
        config['responseType'] = 'blob';

        let data = {
            material: this.props.item.material,
            type: 'img',
            operator: this.props.item.operator,
            part_number: this.props.item.part_number
        }

        axios.post(`${properties.apiUrl}/api/image`, data, config)
            .then(response => {
                let matrixBlob = new Blob([response.data], {type: "image/jpg"});
                let fileReader = new FileReader();
                fileReader.readAsDataURL(matrixBlob);
                fileReader.onload = () => {
                    let result = fileReader.result;
                    this.setState({image: result});
                }
            })
            .catch(error => {
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                } else {
                    console.log(error)
                }
            })
    }

    onModalClose() {
        this.setState({showModal: false, image: ''})
        this.getImage()
    }

    handleSelect(key) {
        this.setState({ key });
    }

    createHersTable(){
        const rows = this.state.hers.map((v,i) => <tr key={i}>
            <td><a href={`https://www.google.com/search?q=${v.part_number}`} target="_blank" rel="noopener noreferrer">
                {v.part_number}
            </a></td>
            <td>{v.manufacturer}</td>
        </tr>)

        return <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th style={{width: '30%'}}>Part Number</th>
                <th>Manufacturer</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    }

    createStructuredTable(){
        const rows = this.state.structured.map((v,i) => <tr key={i}>
            <td>
                {v.key}
            </td>
            <td>{v.value}</td>
        </tr>)

        return <Table striped bordered condensed hover className="Items">
            <thead>
            <tr>
                <th style={{width: '30%'}}>Attribute Name</th>
                <th>Attribute Value</th>
            </tr>
            </thead>
            <tbody>
            {rows}
            </tbody>
        </Table>
    }


    render() {

        let item = this.props.item;

        let excluded = ['filename', 'tag']
        if (item['data_mapper_link'] === 'NA') {
            excluded.push('data_mapper_link')
        }

        let mainColumns = ['material', 'operator', 'description', 'cl_name', 'material_type', 'manufacturer', 'part_number', 'Unit Pirce in USD']
        let mainColumns2 = ['unit', 'location', 'Bin Location', 'stock_level', 'MIN', 'MAX', 'MRP Type', 'surplus_level']
        let mainColumns3 = ['Movement Type', 'demand', 'Transactions PA (ave.)', 'Transactions last 4Y']
        let mainColumns4 = ['Equipment (number of)']
        let mainColumns5 = ['Duplicate', 'Obsolete', 'Insurance Spare', 'Slow Moving']
        let mainColumns6 = ['BOM Clean-up', 'MM Clean-up', 'No BOMs', 'MIN MAX Optimise']
        let mainColumns7 = ['Num of Transactions']
        mainColumns = mainColumns.filter(x => excluded.indexOf(x) === -1)


        let rows = mainColumns.map((v, i) => <div className="row">
            <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
            <div className='col-md-10'>
                {this.getValue(v, item) || ''}</div>
        </div>)

        rows.push(<div className="row">
            <div className='col-md-2'><b>VOR Link:</b></div>
            <div className='col-md-10'><a href='https://vor.cloud/' target="_blank" rel="noopener noreferrer"><img style={{height:24}} src='/images/vor-logo-new-marine.png' /></a></div>
        </div>)


        // let rows2 = mainColumns2.map((v, i) => <div className="row">
        //     <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
        //     <div className='col-md-10'>
        //         {this.getValue(v, item)}</div>
        // </div>)

        let rows2 = this.getSOHTable()

        let rows3 = mainColumns3.map((v, i) => <div className="row">
            <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
            <div className='col-md-10'>
                {this.getValue(v, item) || ''} </div>
        </div>)

        rows3.push(
            <div style={{color: 'black', width: '100%', maxHeight: '250px', overflowY: 'auto'}}>
                {this.state.usage}
            </div>
        )

        let rows4 = mainColumns4.map((v, i) => <div className="row">

            {this.getValue(v, item)}
        </div>)

        let rows5 = mainColumns5.map((v, i) => <div className="row">
            <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
            <div className='col-md-10'>
                {this.getValue(v, item)}</div>
        </div>)

        let rows6 = mainColumns6.map((v, i) => <div className="row">
            <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
            <div className='col-md-10'>
                {this.getValue(v, item)}</div>
        </div>)

        let rows7 = mainColumns7.map((v, i) => <div className="row">
            {this.getValue(v, item)}
        </div>)

        if (this.state.links.length > 0) {
            rows.push(<div className="row">
                <div className='col-md-2'><b>KH Links:</b></div>
                <div className='col-md-10'>
                    {this.state.links.map((v, i) => <a style={{marginRight: 10}} href={v['kh_link']} target="_blank"
                                                       rel="noopener noreferrer" key={i}>Link {i + 1}</a>)}</div>
            </div>)
        }

        // if (!('surplus_level' in item)) {
        //     rows.push(<div className="row">
        //         <div className='col-md-2'><b>Surplus Level:</b></div>
        //         <div className='col-md-10'>{0}</div>
        //     </div>)
        // }

        const editing = !this.state.image ?
            <a onClick={(event) => this.setState({showModal: true})}>Add image</a> :
            <a onClick={(event) => this.setState({showModal: true})}>
                <img alt='edit-image' src="/images/edit-user-blue.png"/>
            </a>

        return (
            <div>
                <h4><b>Description</b></h4>
                <div style={{display: 'inline'}}>
                    {this.state.image && <img style={{maxHeight: 300, verticalAlign: ' top'}} src={this.state.image}/>}
                    {this.props.show && editing}
                </div>
                {rows}
                <Tabs
                    activeKey={this.state.key}
                    onSelect={k => this.handleSelect(k)}>
                    {this.state.structured.length > 0 && <Tab eventKey={0} title="Description" style={{padding: 10}}>
                        {this.createStructuredTable()}
                    </Tab>}
                    <Tab eventKey={11} title="Stock on Hand" style={{padding:10}}>

                        {/*<h4><b>Stock on Hand</b></h4>*/}
                        {rows2}

                    </Tab>
                    <Tab eventKey={1} title="Usage" style={{padding:10}}>
                        {/*/!*<h4><b>Stock Movement</b></h4>*!/*/}
                        {/*{rows3}*/}
                        <OverlayTrigger
                            overlay={<Tooltip>Under construction</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}>
                            <a>Open in the Analytics Hub</a>
                        </OverlayTrigger>
                    </Tab>
                    <Tab eventKey={2} title={"Where-Used (" + (item['Equipment (number of)'] || 0) + ")"} style={{padding:10}}>
                        {/*<h4><b>Bill of Material</b></h4>*/}
                        <OverlayTrigger
                            overlay={<Tooltip>Under construction</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}>
                            <a>Open in the Analytics Hub</a>
                        </OverlayTrigger>
                    </Tab>
                    <Tab eventKey={3} title="Dashboard Summary" style={{padding:10}}>
                        {/*<h4><b>Dashboard Summary</b></h4>*/}
                        <OverlayTrigger
                            overlay={<Tooltip>Under construction</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}>
                            <a>Open in the Analytics Hub</a>
                        </OverlayTrigger>
                    </Tab>
                    <Tab eventKey={4} title="Focus Summary" style={{padding:10}}>
                        {/*<h4><b>Focus Summary</b></h4>*/}
                        <OverlayTrigger
                            overlay={<Tooltip>Under construction</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}>
                            <a>Open in the Analytics Hub</a>
                        </OverlayTrigger>
                    </Tab>
                    <Tab eventKey={5} title={"Transactions (" + (this.props.transactions.length || 0) + ")"} style={{padding:10}}>
                        {/*<h4><b>Transactions</b></h4>*/}
                        <OverlayTrigger
                            overlay={<Tooltip>Under construction</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}>
                            <a>Open in the Analytics Hub</a>
                        </OverlayTrigger>
                    </Tab>

                    <Tab eventKey={9} title={"Potential Duplicates (" + (this.props.matchedRows.length > 0 ? this.props.matchedRows.length - 1 : 0) + ")"} style={{padding:10}}>
                        {this.createCommonalityTable()}
                    </Tab>
                    {this.state.hers.length > 0 && <Tab eventKey={10}
                          title={"HERS Records (" + (this.state.hers.length ||  0) + ")"}
                          style={{padding: 10}}>
                        {this.createHersTable()}
                    </Tab>}

                </Tabs>

                {this.state.showModal &&
                    <AddImageModal show={this.state.showModal} onClose={() => this.onModalClose()} item={this.props.item}/>}
            </div>
        );
    }

}


export class FullData extends Component {

    constructor(props) {
        super(props);
        this.state = {
            item: {},
            mainColumns: [],
            matchedRows: [],
            transactions: [],
            locationsData: [],
            show: false
        }
    }

    getStock(url, operator, material) {
        axios.get(`${url}/api/search/connected?operator=${operator}&material=${material}&showAll=true`, Auth.createConfig())
            .then(json => {
                this.setState({locationsData: json.data.connected});
            })
            .catch(error => {
                console.log(error);
                this.setState({locationsData: []});
                if (error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                }
            });
    }

    sendRequest(url, operator, material){
        axios.get(`${url}/api/search/report?operator=${operator}&material=${material}&showAll=${true}`, Auth.createConfig())
            .then(json => {
                this.setState({ matchedRows: json.data});
            })
            .catch(error => {
                this.setState({matchedRows: []});
                if(error.response.status === 401){
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    this.props.history.replace(Auth.redirectToLogin());
                }
            });
    }


    componentDidMount() {
        if (!sessionStorage.getItem("url")) {
            let url = localStorage.getItem('item_url')
            let token = localStorage.getItem('token')
            let user = localStorage.getItem('hub_user')
            sessionStorage.setItem("item_url", url)
            sessionStorage.setItem("hub_token", token)
            sessionStorage.setItem("hub_user", user)
        }
        let operator = new URLSearchParams(window.location.search).get('operator');
        let material = new URLSearchParams(window.location.search).get('material');
        let url = sessionStorage.getItem("item_url")
        axios.get(`${url}/api/item/full?material=${material}&operator=${operator}`, Auth.createConfig())
            .then(json => {
                this.setState({
                    item: json.data.item[0],
                    items: json.data.item || [],
                    mainColumns: json.data.mainColumns || [],
                    transactions: json.data.transactions || []
                })
                axios.get(`${properties.apiUrl}/api/validate_permission?permission=superadmin`, Auth.createConfig())
                    .then(json => {
                        this.setState({show: true})
                    })
                    .catch(error => {
                        console.log(error.response.data);
                        axios.get(`${properties.apiUrl}/api/validate_permission?permission=admin`, Auth.createConfig())
                            .then(json => {
                                this.setState({show: true})
                            })
                            .catch(error => {
                                console.log(error.response.data);
                            });
                    });
                this.getStock(url, operator, material);
                this.sendRequest(url, operator, material);
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    alert('Your session expired! Please login again!');
                    Auth.logout();
                    console.log(this.props.history)
                    if (this.props.history) {
                        this.props.history.replace(Auth.redirectToLogin());
                    } else {
                        window.location.href = Auth.redirectToLogin()
                    }
                } else {
                    alert('Have troubles opening the page. Redirecting to the search page...');
                    window.location.href = '/search?search=' + operator + ' ' + material
                }
            });


    }


    render() {

        let item = this.state.item;
        if (item && !item.hasOwnProperty('raw'))
            item.raw = item.description;

        return (
            <div className='catalog-root center-block'>
                <div className="App-query center-block row">
                    <a href='/'><span className="col-md-3 App-logo"/></a>
                </div>
                <h3 className='text-center'>Item: {item.operator} {item.material}</h3>
                <Panel bsStyle="primary">
                    <Panel.Body>

                        <MainInfo item={item}
                                  items={this.state.items}
                                  show={this.state.show}
                                  mainColumns={this.state.mainColumns}
                                  matchedRows={this.state.matchedRows}
                                  transactions={this.state.transactions}
                                  locationsData={this.state.locationsData}
                                  request={() => this.setState({selectedItem: item})}/>
                    </Panel.Body>
                </Panel>

                {/*<AdditionalInfo item={item}*/}
                {/*                mainColumns = {this.state.mainColumns} />*/}

                <RequestModal item={this.state.selectedItem} isCommonality={false}
                              onClose={() => this.setState({selectedItem: null})}/>
                <Menu history={this.props.history}/>
            </div>
        );
    }
}
